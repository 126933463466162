import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from 'react-intl'

import { getURI } from '~utilities'

import MobileSection from '~components/nav/mobile-section'
import ENGLISH_ABOUT from '~data/en-US/about'
import ENGLISH_BROWSE_BY_AGES from '~data/en-US/browse-by-ages'
import ENGLISH_ACTIVITIES from '~data/en-US/activities'
import SPANISH_ABOUT from '~data/es-MX/about'
import SPANISH_BROWSE_BY_AGES from '~data/es-MX/browse-by-ages'
import SPANISH_ACTIVITIES from '~data/es-MX/activities'

const MobileNavigation = ({ setShowMenu }) => {
  const { locale, messages } = useIntl()
  const key = locale.toLowerCase()

  let aboutNav = {}
  let browseByAgesNav = {}
  let activitiesNav = {}
  switch (locale) {
    case 'en-US':
      aboutNav = ENGLISH_ABOUT
      browseByAgesNav = ENGLISH_BROWSE_BY_AGES
      activitiesNav = ENGLISH_ACTIVITIES
      break
    case 'es-MX':
      aboutNav = SPANISH_ABOUT
      browseByAgesNav = SPANISH_BROWSE_BY_AGES
      activitiesNav = SPANISH_ACTIVITIES
      break
    default:
      aboutNav = ENGLISH_ABOUT
      browseByAgesNav = ENGLISH_BROWSE_BY_AGES
      activitiesNav = ENGLISH_ACTIVITIES
      break
  }

  const data = useStaticQuery(staticQuery)
  const [menuState, setMenuState] = useState(-1)
  const [submenuState, setSubmenuState] = useState('')

  function toggleState(id) {
    if (menuState !== id) {
      setMenuState(id)
      setSubmenuState('')
    } else {
      setMenuState(-1)
      setSubmenuState('')
    }
  }

  function toggleSubmenu(section) {
    if (submenuState !== section) {
      setSubmenuState(section)
    } else {
      setSubmenuState('')
    }
  }

  const ageRegex = /^(Newborn |Baby |Toddler |Preschooler )/
  function alphabetizeByName(a, b) {
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    return 0
  }

  const servicesPart1 = [
    {
      name: messages['all-services'],
      uri: 'services'
    }
  ]
  let servicesPart2 = []
  data.services.edges
    .filter(edge => edge.node.node_locale === locale)
    .forEach(edge => {
      servicesPart2 = servicesPart2.concat([
        {
          name: edge.node.title,
          uriFull: getURI(edge.node, key)
        }
      ])
    })

  const services = servicesPart1.concat(servicesPart2)

  const servicesNav = [
    {
      section: 'Services',
      links: services
    }
  ]

  let specialTopics = []
  data.specialTopics.nodes
    .find(({ node_locale }) => node_locale === locale)
    .topics.forEach(edge => {
      specialTopics = specialTopics.concat([
        {
          name: edge.title,
          uriFull: getURI(edge, key)
        }
      ])
    })

  let newbornTopics = []
  data.newbornTopics.edges
    .filter(edge => edge.node.node_locale === locale)
    .forEach(edge => {
      newbornTopics = newbornTopics.concat([
        {
          name: edge.node.title.replace(ageRegex, ''),
          uriFull: getURI(edge.node, key)
        }
      ])
    })
  newbornTopics.sort(alphabetizeByName)

  let babyTopics = []
  data.babyTopics.edges
    .filter(edge => edge.node.node_locale === locale)
    .forEach(edge => {
      babyTopics = babyTopics.concat([
        {
          name: edge.node.title.replace(ageRegex, ''),
          uriFull: getURI(edge.node, key)
        }
      ])
    })
  babyTopics.sort(alphabetizeByName)

  let toddlerTopics = []
  data.toddlerTopics.edges
    .filter(edge => edge.node.node_locale === locale)
    .forEach(edge => {
      toddlerTopics = toddlerTopics.concat([
        {
          name: edge.node.title.replace(ageRegex, ''),
          uriFull: getURI(edge.node, key)
        }
      ])
    })
  toddlerTopics.sort(alphabetizeByName)

  let preschoolerTopics = []
  data.preschoolerTopics.edges
    .filter(edge => edge.node.node_locale === locale)
    .forEach(edge => {
      preschoolerTopics = preschoolerTopics.concat([
        {
          name: edge.node.title.replace(ageRegex, ''),
          uriFull: getURI(edge.node, key)
        }
      ])
    })
  preschoolerTopics.sort(alphabetizeByName)

  const topicsNav = [
    {
      section: messages['special-topics'],
      links: specialTopics
    },
    {
      section: messages['newborns'],
      links: newbornTopics
    },
    {
      section: messages['babies'],
      links: babyTopics
    },
    {
      section: messages['toddlers'],
      links: toddlerTopics
    },
    {
      section: messages['preschoolers'],
      links: preschoolerTopics
    }
  ]

  return (
    <>
      <MobileSection
        title={messages['about']}
        path='about'
        sections={aboutNav}
        id={1}
        menuState={menuState}
        submenuState={submenuState}
        toggleState={toggleState}
        toggleSubmenu={toggleSubmenu}
        setShowMenu={setShowMenu}
      />
      <MobileSection
        title={messages['find-help']}
        path='services'
        sections={servicesNav}
        id={2}
        menuState={menuState}
        submenuState={submenuState}
        toggleState={toggleState}
        toggleSubmenu={toggleSubmenu}
        setShowMenu={setShowMenu}
      />
      <MobileSection
        title={messages['browse-by-ages']}
        path=''
        sections={browseByAgesNav}
        id={3}
        menuState={menuState}
        submenuState={submenuState}
        toggleState={toggleState}
        toggleSubmenu={toggleSubmenu}
        setShowMenu={setShowMenu}
      />
      <MobileSection
        title={messages['explore-topics']}
        path=''
        sections={topicsNav}
        id={4}
        menuState={menuState}
        submenuState={submenuState}
        toggleState={toggleState}
        toggleSubmenu={toggleSubmenu}
        setShowMenu={setShowMenu}
        truncate
      />
      <MobileSection
        title={messages['videos']}
        path='videos'
        sections={false}
        id={5}
        toggleState={toggleState}
        setShowMenu={setShowMenu}
      />
      <MobileSection
        title={messages['activities-and-more']}
        path='activities'
        sections={activitiesNav}
        id={6}
        menuState={menuState}
        submenuState={submenuState}
        toggleState={toggleState}
        toggleSubmenu={toggleSubmenu}
        setShowMenu={setShowMenu}
      />
    </>
  )
}

export default MobileNavigation

const staticQuery = graphql`
  {
    services: allContentfulServiceDetailPage(sort: { fields: name }) {
      edges {
        node {
          title: name
          internal {
            type
          }
          node_locale
          slug
          type
        }
      }
    }
    specialTopics: allContentfulSettings(filter: { title: { eq: "Global Settings" } }) {
      nodes {
        node_locale
        topics: specialTopics {
          __typename
          ... on ContentfulTopicPage {
            title
            internal {
              type
            }
            node_locale
            slug
            type
          }
        }
      }
    }
    newbornTopics: allContentfulTopicPage(
      filter: { primaryTopics: { elemMatch: { slug: { eq: "newborn" } } } }
      sort: { fields: title }
    ) {
      edges {
        node {
          title
          internal {
            type
          }
          node_locale
          slug
          type
        }
      }
    }
    babyTopics: allContentfulTopicPage(
      filter: { primaryTopics: { elemMatch: { slug: { eq: "baby" } } } }
      sort: { fields: title }
    ) {
      edges {
        node {
          title
          internal {
            type
          }
          node_locale
          slug
          type
        }
      }
    }
    toddlerTopics: allContentfulTopicPage(
      filter: { primaryTopics: { elemMatch: { slug: { eq: "toddler" } } } }
      sort: { fields: title }
    ) {
      edges {
        node {
          title
          internal {
            type
          }
          node_locale
          slug
          type
        }
      }
    }
    preschoolerTopics: allContentfulTopicPage(
      filter: { primaryTopics: { elemMatch: { slug: { eq: "preschooler" } } } }
      sort: { fields: title }
    ) {
      edges {
        node {
          title
          internal {
            type
          }
          node_locale
          slug
          type
        }
      }
    }
  }
`
