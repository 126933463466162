module.exports = {
  // Navigation
  'en-US': 'eng',
  'es-MX': 'esp',
  'en-US-Full': 'English',
  'es-MX-Full': 'Spanish',

  // Age groups
  'expected': 'Expected soon!',
  'newborn': 'Newborn',
  'newborns': 'Newborns',
  'newborns-with-age': 'Newborns (0-3 mos)',
  'baby': 'Baby',
  'babies': 'Babies',
  'babies-with-age': 'Babies (4-11 mos)',
  'toddler': 'Toddler',
  'toddlers': 'Toddlers',
  'toddlers-with-age': 'Toddlers (1-2 yrs)',
  'preschooler': 'Preschooler',
  'preschoolers': 'Preschoolers',
  'preschoolers-with-age': 'Preschoolers (3-5 yrs)',
  'not-child': 'All grown up!',
  'months': 'Months',
  'years': 'Years',

  // General titles and labels
  'ages': 'Ages',
  'all': 'All',
  'all-ages': 'All ages',
  'contact-info': 'Contact info',
  'counties': 'Counties',
  'county-map': 'County map',
  'email': 'Email',
  'home-indicator': 'You are on the homepage',
  'label-children': 'Due date / Child’s birthday',
  'label-interests': 'Interests',
  'language': 'Language',
  'menu': 'Menu',
  'month': 'Month',
  'new-password': 'New password',
  'no-account': 'Don’t have an account?',
  'no-children': 'No children',
  'no-interests': 'No interests',
  'old': 'old',
  'optional': 'optional',
  'parent-guide': 'CA Parent Guide',
  'password': 'Password',
  'phone': 'Phone',
  're-enter-email': 'Re-enter your email',
  'state-site': 'Visit the State Site to learn more about First 5 California',
  'stronger-starts': 'Toxic Stress',
  'stronger-starts-footer': 'How to Protect Children From Toxic Stress',
  'summary': 'Summary',
  'toxic-stress': 'Toxic Stress Response',
  'verification-code': 'Verification code',
  'website': 'Website',
  'website-subtitle': 'California’s parenting website',
  'year': 'Year',
  'your-children': 'Your children',
  'your-interests': 'Your interests',
  'zip-codes': 'Zip codes',

  // Main titles and labels
  'about': 'About',
  'activities-and-more': 'Activities and more',
  'all-services': 'All services',
  'articles': 'Articles',
  'articles-category': 'Category articles',
  'browse-by-ages': 'Browse by ages',
  'county-search-label': 'Type your county name or zip code',
  'dashboard': 'My account',
  'explore-topics': 'Explore topics',
  'featured': 'Featured',
  'featured-this-week': 'Featured this week',
  'featured-this-month': 'Featured this month',
  'filtered-by': 'Filtered by',
  'find-help': 'Find help',
  'hide-filters': 'Hide filters',
  'hide-sources': 'Hide sources',
  'home': 'Home',
  'loading': 'Loading...',
  'more-activities': 'More activities and games',
  'more-books': 'More resources for you',
  'more-videos': 'Related videos',
  'new-and-trending': 'New and trending',
  'no-county-results': 'No county name or zip code matching',
  'none': 'None',
  'our-mission': 'Our mission',
  'our-purpose': 'Our purpose',
  'partners': 'Partners',
  'recommended-articles': 'Recommended articles',
  'recommended-search-terms': 'Recommended search terms',
  'series-info': 'Series information',
  'services': 'Services',
  'show-filters': 'Show filters',
  'show-sources': 'Show sources',
  'special-topics': 'Special topics',
  'stay-in-touch': 'Stay in touch',
  'table-of-contents': 'Skip ahead',
  'trending-questions': 'Trending questions',
  'videos': 'Videos',
  'videos-category': 'Category videos',
  'top-picks-for-you': 'Top picks for you',

  // Sidebar titles and labels
  'activities-label': 'Activities',
  'activities-title': 'Parents’ top 3 activities',
  'reviews-label': 'Read the reviews',
  'reviews-title': 'Parents recommend',
  'services-label': 'Local help',
  'video-label': 'Featured video',

  // Footer titles and labels
  'conditions-of-use': 'Conditions of use',
  'contact-us': 'Contact us',
  'disclaimer': 'This Internet site provides information of a general nature and is designed for educational purposes only. If you have any concerns about your own health or the health of your child, you should always consult with a physician or other healthcare professional. Please review the Conditions of Use before using this site. Your use of the site indicates your agreement to be bound by the ',
  'privacy-policy': 'Privacy policy',
  'site-map': 'Sitemap',
  'talk-read-sing': 'Talk. Read. Sing.® Promoting public awareness of issues affecting early childhood development, early brain development, and parenting.',

  // Calls to action
  'add-child': 'Add child',
  'back': 'Back',
  'clear-filters': 'Clear filters',
  'close': 'Close',
  'continue': 'Continue',
  'continue-watching': 'Continue watching',
  'contributed-by': 'Contributed by',
  'download-now': 'Download now',
  'download-pdf': 'Download PDF',
  'get-verification-code': 'Get verification code',
  'hide-all': 'Hide all',
  'learn-more': 'Learn more',
  'learn-more-state-site': 'Visit the First 5 California State Site to learn more',
  'log-in-sign-up': 'Log in / Sign up',
  'log-out': 'Log out',
  'more': 'More',
  'open': 'Open',
  'read-more': 'Read more',
  'read-online': 'Read online',
  'remove': 'Remove',
  'reset-password': 'Reset password',
  'search': 'Search',
  'search-this-site': 'Search this site',
  'see-all': 'See all',
  'see-all-questions': 'See all questions',
  'see-more-activities': 'Get activities',
  'sign-in': 'Sign in',
  'sign-up': 'Sign up',
  'skip': 'Skip to main content',
  'start-over': 'Start over',
  'update': 'Update',
  'verify-account': 'Verify account',

  // Sharing and tags
  'share': 'Share',
  'share-page': 'Share this post:',
  'share-prompt': 'Find this useful?',
  'tag': 'Tag',
  'tags': 'Tags',

  // 404 page
  'page-not-found-title': 'Error 404: page not found',
  'page-not-found-body': 'Uh-oh!',
  'page-not-found-cta': 'Looks like this page went bye-bye.',
  'page-not-found-link': 'Head back home and say hello!',

  // Search
  'age': 'Age',
  'type': 'Type',
  'activity-type': 'Activity Type',
  'topic': 'Topic',
  'activity': 'Activity',
  'article': 'Article',
  'book': 'Book',
  'resource': 'Resource',
  'service': 'Service',
  'video': 'Video',
  'notanactivity': 'Not an activity',
  'activitiesandgames': 'Activities and games',
  'childrensbooks': 'Children’s books',
  'coloringsheets': 'Coloring sheets',
  'talking': 'Talking',
  'reading': 'Reading',
  'search-all-pages': 'Search all pages here...',
  'search-activities': 'Search activities here...',
  'search-videos': 'Search videos here...',
  'results-found': 'Results found',

  // User accounts
  'sign-in-title': 'Join our First 5 family – it’s free!',
  'sign-in-description': 'Enjoy personalized content based on your child’s age every time you visit our site.',
  'reset-password-title': 'Reset your password.',
  'reset-password-description': 'Let’s get you back in!',
  'sign-up-title': 'Sign up in seconds.',
  'sign-up-description': 'Fast, secure, easy. And rest assured: we’ll never sell or share your info.',
  'verification-title': 'Almost done!',
  'verification-description': 'We’ve emailed you a unique verification code. Just plug it in here to confirm your new account.',
  'dashboard-title': 'You’re in. Welcome!',
  'dashboard-description': 'Review and update your details here. (You can always come back and edit later, too!)',
  'dashboard-welcome': 'Welcome to the First 5 Family. We’re so glad you’re here! Our site is designed to provide you with a full range of information to help you on your parenting journey – from pregnancy through the preschool years – along with more personalized content based on the age(s) of your children. Ready, set, explore!',
  'start-exploring': 'Start exploring',
  'welcome': 'Welcome',
  'name': 'Name',
  'label-newsletter': 'Join our mailing list',
  'marketing-opt-in-disclaimer': 'Yes! I’d like to receive email updates from First 5 California regarding new website content and information that will be useful for my family.',

  // Error messages and alerts
  'alert-code-sent': 'Verification code sent!',
  'error-child': 'Both a month and year must be selected. If you would like to remove an entry, click the “remove” button.',
  'error-code-mismatch': 'You entered the wrong verification code. Please try again.',
  'error-email': 'Your email address is required.',
  'error-min-length': 'Passwords must be at least 8 characters long.',
  'error-not-authorized': 'Wrong username or password. Please try again.',
  'error-password': 'A password is required.',
  'error-reset-user-not-found': 'Oops! Looks like this account doesn’t exist. Don’t worry – just go back to sign up for one instead! It’s fast and easy.',
  'error-user-not-found': 'This account does not exist. Please click sign up below instead.',
  'error-username-exists': 'This account already exists. Please go back to sign in or reset your password.',
  'error-verify': 'Your account must be verified first.'
}
