import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'

import { Tags } from '~components/shared'

export const MediaCard = ({
  className,
  image,
  title,
  question,
  type,
  caption,
  tags,
  cta,
  displayStyle,
  url,
  onlyShowCaptionWhenWide,
  sidebar
}) => {
  const data = useStaticQuery(staticQuery)
  const { settings } = data
  const placeholderImage = settings.mediaCardPlaceholderImage.file.url

  // remove empty tags from array.
  tags = tags && tags.filter(item => item)

  let showCaption = true
  if (displayStyle === 'card' && onlyShowCaptionWhenWide) {
    showCaption = false
  }

  let isThumbnail = false
  if (image && image.includes('youtube')) {
    isThumbnail = true
  }

  if (displayStyle === 'question') {
    return (
      <Link to={url} className='flex justify-between items-center w-full py-4 border-b border-gray group'>
        <div className={cx(sidebar ? 'pr-2 font-bold text-xs leading-snug' : 'headline-5 w-3/4 lg:w-auto')}>
          {question}
        </div>
        <div>
          <div className='icon-large bg-purple border border-purple group-hover:bg-white text-white'>
            <em className='fas fa-caret-right group-hover:text-purple' />
          </div>
        </div>
      </Link>
    )
  } else if (displayStyle === 'card') {
    return (
      <Link to={url} className={cx('flex flex-col w-full shadow-lg mb-1', displayStyle === 'list' && 'lg:flex-row', className)}>
        <div
          className={cx('block', displayStyle === 'list' ? 'w-full lg:w-1/3' : 'w-full')}
          title={title}
          aria-hidden
        >
          <div className=''>
            <div className='overflow-hidden'>
              <img
                src={`${image}`}
                className='md:w-full object-cover'
                alt='N/A'
                aria-hidden
              />
            </div>
          </div>
        </div>
        <div className={cx('flex flex-col w-full p-4', displayStyle === 'list' ? 'lg:w-2/3' : 'flex-grow')}>
          <div className='font-extrabold text-xl leading-tight mb-4 text-blue'>
            {title}
          </div>
        </div>
      </Link>
    )
  } else {
    return (
      <div className={cx('flex flex-col w-full shadow-lg mb-1', displayStyle === 'list' && 'lg:flex-row', className)}>
        <Link
          to={url}
          className={cx('block', displayStyle === 'list' ? 'w-full lg:w-1/3' : 'w-full')}
          title={title}
          aria-hidden
        >
          <div className='aspect-ratio-4/3'>
            <div className='overflow-hidden'>
              {displayStyle === 'list' ? (
                <img
                  src={`${image || placeholderImage}?fm=jpg&fl=progressive&w=320&q=60`}
                  className={cx('w-full h-full object-cover', isThumbnail && 'thumbnail')}
                  alt='N/A'
                  aria-hidden
                />
              ) : (
                <img
                  src={`${image || placeholderImage}?fm=jpg&fl=progressive&w=480&q=70`}
                  className={cx('w-full h-full object-cover', isThumbnail && 'thumbnail')}
                  alt='N/A'
                  aria-hidden
                />
              )}
            </div>
          </div>
        </Link>
        <div className={cx('flex flex-col w-full p-4', displayStyle === 'list' ? 'lg:w-2/3' : 'flex-grow')}>
          <Link to={url} className='font-extrabold text-xl leading-tight mb-4 text-blue'>
            {title}
          </Link>
          {caption && showCaption && (
            <Link to={url} className='font-normal text-sm leading-normal text-clamp-4 mb-2'>
              {caption}
            </Link>
          )}
          {cta && (
            <div className='relative z-0 flex flex-col justify-end flex-grow'>
              <div>
                <Link to={url} className='button button-pink mt-4 mb-0'>
                  <div>
                    {type === 'Video' ? (
                      <FormattedMessage id='continue-watching' />
                    ) : (
                      <FormattedMessage id='read-more' />
                    )}
                    <em className='fas fa-caret-right ml-2' />
                  </div>
                </Link>
              </div>
            </div>
          )}
          {tags && (
            <div className='-mx-1 -mb-1'>
              <Tags type={type || null} tags={tags} hideTitle />
            </div>
          )}
        </div>
      </div>
    )
  }
}

MediaCard.defaultProps = {
  className: '',
  type: 'Missing Type',
  caption: null,
  tags: null,
  cta: false,
  displayStyle: 'tall'
}

MediaCard.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  caption: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.object),
  cta: PropTypes.bool,
  url: PropTypes.string,
  displayStyle: PropTypes.string,
  onlyShowCaptionWhenWide: PropTypes.bool
}

const staticQuery = graphql`
  query {
    settings: contentfulSettings(title: { eq: "Global Settings" }) {
      mediaCardPlaceholderImage {
        file {
          url
        }
      }
    }
  }
`
