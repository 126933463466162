import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl, FormattedMessage } from 'react-intl'

import { getURI } from '~utilities'

import Section from '~components/nav/section'
import LanguageSwitcher from '~components/nav/language-switcher'
import ENGLISH_ABOUT from '~data/en-US/about'
import ENGLISH_BROWSE_BY_AGES from '~data/en-US/browse-by-ages'
import ENGLISH_ACTIVITIES from '~data/en-US/activities'
import SPANISH_ABOUT from '~data/es-MX/about'
import SPANISH_BROWSE_BY_AGES from '~data/es-MX/browse-by-ages'
import SPANISH_ACTIVITIES from '~data/es-MX/activities'

const Navigation = ({ section }) => {
  const { locale, messages } = useIntl()
  const key = locale.toLowerCase()

  let aboutNav = {}
  let browseByAgesNav = {}
  let activitiesNav = {}
  switch (locale) {
    case 'en-US':
      aboutNav = ENGLISH_ABOUT
      browseByAgesNav = ENGLISH_BROWSE_BY_AGES
      activitiesNav = ENGLISH_ACTIVITIES
      break
    case 'es-MX':
      aboutNav = SPANISH_ABOUT
      browseByAgesNav = SPANISH_BROWSE_BY_AGES
      activitiesNav = SPANISH_ACTIVITIES
      break
    default:
      aboutNav = ENGLISH_ABOUT
      browseByAgesNav = ENGLISH_BROWSE_BY_AGES
      activitiesNav = ENGLISH_ACTIVITIES
      break
  }

  const data = useStaticQuery(staticQuery)

  const ageRegex = /^(Newborn |Baby |Toddler |Preschooler )/
  function alphabetizeByName(a, b) {
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    return 0
  }

  const servicesPart1 = [
    {
      name: messages['all'],
      uri: 'services'
    }
  ]
  let servicesPart2 = []
  data.services.edges
    .filter(edge => edge.node.node_locale === locale)
    .forEach(edge => {
      servicesPart2 = servicesPart2.concat([
        {
          name: edge.node.title,
          uriFull: getURI(edge.node, key)
        }
      ])
    })

  const services = servicesPart1.concat(servicesPart2)

  const servicesNav = [
    {
      section: messages['services'],
      links: services
    }
  ]

  let specialTopics = []
  data.specialTopics.nodes
    .find(({ node_locale }) => node_locale === locale)
    .topics.forEach(edge => {
      specialTopics = specialTopics.concat([
        {
          name: edge.title,
          uriFull: getURI(edge, key)
        }
      ])
    })

  let newbornTopics = []
  data.newbornTopics.edges
    .filter(edge => edge.node.node_locale === locale)
    .forEach(edge => {
      newbornTopics = newbornTopics.concat([
        {
          name: edge.node.title.replace(ageRegex, ''),
          uriFull: getURI(edge.node, key)
        }
      ])
    })
  newbornTopics.sort(alphabetizeByName)

  let babyTopics = []
  data.babyTopics.edges
    .filter(edge => edge.node.node_locale === locale)
    .forEach(edge => {
      babyTopics = babyTopics.concat([
        {
          name: edge.node.title.replace(ageRegex, ''),
          uriFull: getURI(edge.node, key)
        }
      ])
    })
  babyTopics.sort(alphabetizeByName)

  let toddlerTopics = []
  data.toddlerTopics.edges
    .filter(edge => edge.node.node_locale === locale)
    .forEach(edge => {
      toddlerTopics = toddlerTopics.concat([
        {
          name: edge.node.title.replace(ageRegex, ''),
          uriFull: getURI(edge.node, key)
        }
      ])
    })
  toddlerTopics.sort(alphabetizeByName)

  let preschoolerTopics = []
  data.preschoolerTopics.edges
    .filter(edge => edge.node.node_locale === locale)
    .forEach(edge => {
      preschoolerTopics = preschoolerTopics.concat([
        {
          name: edge.node.title.replace(ageRegex, ''),
          uriFull: getURI(edge.node, key)
        }
      ])
    })
  preschoolerTopics.sort(alphabetizeByName)

  const topicsNav = [
    {
      section: messages['special-topics'],
      links: specialTopics
    },
    {
      section: messages['newborns'],
      links: newbornTopics
    },
    {
      section: messages['babies'],
      links: babyTopics
    },
    {
      section: messages['toddlers'],
      links: toddlerTopics
    },
    {
      section: messages['preschoolers'],
      links: preschoolerTopics
    }
  ]

  const parentGuideLink = `//parentguide.first5california.com/${key}/`

  return (
    <nav className='hidden lg:block lg:bg-blue font-nunito font-extrabold text-white'>
      <div className='max-w-page mx-auto px-4'>
        <div className='flex justify-between -mx-4'>
          <ul className='flex items-center flex-grow space-x-4'>
            <Section title={messages['about']} path='about' sections={aboutNav} section={section} />
            <Section title={messages['find-help']} path='services' sections={servicesNav} section={section} />
            <Section title={messages['browse-by-ages']} path='' sections={browseByAgesNav} section={section} />
            <Section title={messages['explore-topics']} path='' sections={topicsNav} section={section} />
            <Section title={messages['videos']} path='videos' section={section} />
            <Section
              title={messages['activities-and-more']}
              path='activities'
              sections={activitiesNav}
              section={section}
            />
          </ul>
          <div className='flex items-center border-x border-white'>
            <a
              href='https://first5california.strongerstarts.com/'
              className='h-full p-4 leading-snug hover:bg-cyan-dark hover:text-black group'
              target='_blank'
              rel='noreferrer noopener'
            >
              <FormattedMessage id='stronger-starts' />
            </a>
          </div>
          <div className='flex items-center border-x border-white'>
            <a
              href={parentGuideLink}
              className='h-full p-4 leading-snug hover:bg-cyan-dark hover:text-black group'
              target='_blank'
              rel='noreferrer noopener'
            >
              <FormattedMessage id='parent-guide' />
            </a>
          </div>
          <div className='flex justify-end items-center'>
            <LanguageSwitcher />
            {/* <button
              onClick={() => console.log('test')}
              className='block px-4 text-xl cursor-pointer'
              aria-label='accessibility'
            >
              <em className='fas fa-universal-access' />
            </button> */}
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navigation

const staticQuery = graphql`
  {
    services: allContentfulServiceDetailPage(sort: { fields: name }) {
      edges {
        node {
          title: name
          internal {
            type
          }
          node_locale
          slug
          type
        }
      }
    }
    specialTopics: allContentfulSettings(filter: { title: { eq: "Global Settings" } }) {
      nodes {
        node_locale
        topics: specialTopics {
          __typename
          ... on ContentfulTopicPage {
            title
            internal {
              type
            }
            node_locale
            slug
            type
          }
        }
      }
    }
    newbornTopics: allContentfulTopicPage(
      filter: { primaryTopics: { elemMatch: { slug: { eq: "newborn" } } } }
      sort: { fields: title }
    ) {
      edges {
        node {
          title
          internal {
            type
          }
          node_locale
          slug
          type
        }
      }
    }
    babyTopics: allContentfulTopicPage(
      filter: { primaryTopics: { elemMatch: { slug: { eq: "baby" } } } }
      sort: { fields: title }
    ) {
      edges {
        node {
          title
          internal {
            type
          }
          node_locale
          slug
          type
        }
      }
    }
    toddlerTopics: allContentfulTopicPage(
      filter: { primaryTopics: { elemMatch: { slug: { eq: "toddler" } } } }
      sort: { fields: title }
    ) {
      edges {
        node {
          title
          internal {
            type
          }
          node_locale
          slug
          type
        }
      }
    }
    preschoolerTopics: allContentfulTopicPage(
      filter: { primaryTopics: { elemMatch: { slug: { eq: "preschooler" } } } }
      sort: { fields: title }
    ) {
      edges {
        node {
          title
          internal {
            type
          }
          node_locale
          slug
          type
        }
      }
    }
  }
`
